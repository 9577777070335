import { createApp } from 'vue';
import App from './App.vue';

// Import and call the exported function from amplify.js
import configureAmplify from './plugins/amplify.js';

async function initApp() {
  await configureAmplify();
  createApp(App).mount('#app');
}

initApp();
