<template>
    <div v-if="show" class="confirm-dialog">
        <div class="dialog-content">
            <p>Are you sure you want to publish your changes?</p>
            <p>This change impacts all selected users immediately!</p>
            <div class="buttons">
                <button class="blue" @click="handleYes">Publish</button>
                <button @click="handleNo">Discard</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['show'],
    methods: {
        handleYes() {
            this.$emit('confirm');
        },
        handleNo() {
            this.$emit('cancel');
        },
    },
};
</script>

<style scoped>
.confirm-dialog {
    position: fixed; /* Changed from absolute to fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}


.dialog-content {
    position: fixed;
    top:250px;
    background-color: #161617; /* Or any color you prefer */
    padding: 20px;
    color: #F94144;
    border: 2px solid #F94144;
    text-align: center;
    padding: 60px 100px;
    /* Add more styling as needed */
}

.dialog-content p {
    font-weight: bold;
    margin-bottom: 1em;
}

.dialog-content button {
    margin: 0 1em;
    background: transparent;
    color: #9E9999;
    border: 1px solid #9E9999;
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
    margin-top: 30px;

    &.blue{
        color: #7BA3FF;
        border-color: #7BA3FF;
    }
}

</style>