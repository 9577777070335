import { Auth } from 'aws-amplify';
// import { v4 as uuidv4 } from 'uuid';

export async function load() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (err) {
    return null;
  }
}

export async function login(email, password) {
  const user = await Auth.signIn({
    username: email,
    password,
  });
  return user;
}


// export async function signUp(password, attributes) {
//   const username = uuidv4();
//   const { user } = await Auth.signUp({
//     username,
//     password,
//     attributes,
//   });
//   if (user) {
//     const signedInUser = await Auth.signIn({
//       username,
//       password,
//     });
//     return signedInUser;
//   }
//   return user;
// }

// export async function confirmSignUp(email, code) {
//   await Auth.confirmSignUp(email, code);
// }

// export async function changePassword(currentPassword, newPassword) {
//   const user = await Auth.currentAuthenticatedUser();
//   await Auth.changePassword(user, currentPassword, newPassword);
//   return user;
// }

// export async function updateEmail(email) {
//   const user = await Auth.currentAuthenticatedUser();
//   await Auth.updateUserAttributes(user, {
//     email,
//   });
//   const updatedUser = await Auth.currentAuthenticatedUser();
//   return updatedUser;
// }

// export async function confirmEmail(code) {
//   await Auth.verifyCurrentUserAttributeSubmit('email', code);
//   const updatedUser = await Auth.currentAuthenticatedUser();
//   return updatedUser;
// }

// export async function updateAddress(address, taxNumber) {
//   const user = await Auth.currentAuthenticatedUser();
//   await Auth.updateUserAttributes(user, {
//     address: JSON.stringify(address),
//     'custom:tax_number': taxNumber,
//   });
//   const updatedUser = await Auth.currentAuthenticatedUser();
//   return updatedUser;
// }

// export async function sendResetCode(email) {
//   await Auth.forgotPassword(email);
// }

// export async function resetPassword(email, password, code) {
//   await Auth.forgotPasswordSubmit(email, code, password);
// }

export async function logout() {
  await Auth.signOut();
}
