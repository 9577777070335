<template>
    <div class="notification-editor">
      <form @submit.prevent="handleSubmit">
        <div class="inputArea">
            <div class="form-group">
            <label for="productId">Product ID</label>
            <select id="productId" v-model="notification.productId">
                <option v-for="product in products" :key="product.productId" :value="product.productId">{{ product.name }}</option>
            </select>
            </div>
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" id="title" v-model="notification.title" />
            </div>
    
            <div class="form-group">
                <label for="body">Body</label>
                <textarea id="body" v-model="notification.body"></textarea>
            </div>
    
            <div class="form-group">
                <label for="url">URL</label>
                <input type="text" id="url" v-model="notification.url" />
            </div>
    
            <div class="form-group">
                <label for="metaJson">Meta JSON</label>
                <textarea id="metaJson" v-model="notification.metaJson"></textarea>
            </div>
    
            <div class="form-group">
                <label for="image">Image</label>
                <input type="file" id="image" @change="handleImageUpload" />
                <img v-if="notification.imageBase64" :src="'data:image/png;base64,' + notification.imageBase64" alt="Uploaded Image" />
            </div>
            
            <div class="form-group">
                <label for="publicationTime">Publication Time</label>
                <input type="datetime-local" id="publicationTime" v-model="publicationTimeLocal" />
            </div>
        </div>
        <div class="saveButtons">
            <button class="save" type="submit" :disabled="!isFormValid">
                Save
            </button>
            <button class="discard" type="button" @click="handleDiscard">
                Discard
            </button>
        </div>
      </form>
      <hr>
      <div class="previewSection">
        <h2>Preview</h2>
        <div class="previewTile">
            <div class="blueDot"></div>
            <img v-if="notification.imageBase64" :src="'data:image/png;base64,' + notification.imageBase64" alt="Uploaded Image" />
            <div class="elementText">
                <h3>{{ notification.title }}</h3>
                <p>{{ notification.body }}</p>
            </div>
            <div class="contentRight">
                <p class="date">{{ formatDate(notification.publicationTime) }}</p>
                <button>Dismiss</button>
            </div>
        </div>
        
      </div>
      <confirm-dialog :show="showConfirmDialog" @confirm="handleConfirm" @cancel="handleCancel"></confirm-dialog>
    </div>
</template>

<script>
import { ref, watch } from 'vue';
import axios from 'axios';
import secretsConfig from '../secretsConfig.js';
import { Amplify } from 'aws-amplify';

import ConfirmDialog from './ConfirmDialog.vue';

export default {
    name: 'NotificationEditor',
    props: {
        initialNotification: Object,
        products: Array,
    },
    components: {
        ConfirmDialog,
    },
    computed: {
        publicationTimeLocal: {
            get() {
                let date = new Date(this.notification.publicationTime);
                let year = date.getFullYear();
                let month = ("0" + (date.getMonth() + 1)).slice(-2);
                let day = ("0" + date.getDate()).slice(-2);
                let hours = ("0" + date.getHours()).slice(-2);
                let minutes = ("0" + date.getMinutes()).slice(-2);
                return `${year}-${month}-${day}T${hours}:${minutes}`;
            },
            set(newValue) {
                let date = new Date(newValue);
                this.notification.publicationTime = Math.floor(date.getTime());
            }
        },
        isFormValid() {
            return this.notification.productId &&
                this.notification.title.trim() !== '' &&
                this.notification.body.trim() !== '' &&
                this.notification.url.trim() !== '';
        },
    },
    setup(props, { emit }) {
        const showConfirmDialog = ref(false);
        const notification = ref({ ...props.initialNotification });

        const handleSubmit = () => {
            showConfirmDialog.value = true;
        };
        
    
        const handleConfirm = async () => {
            showConfirmDialog.value = false;
            await upsertNotification(notification.value); // Await the completion of upsert
            emit('close'); // Emit close after upsert completes
        };


        const handleCancel = () => {
            showConfirmDialog.value = false;
        };

        const handleDiscard = () => {
            showConfirmDialog.value = false;
            emit('close');
        };


        watch(() => props.initialNotification, (newVal) => {
            notification.value = { ...newVal };
            if (newVal && newVal.productId) {
                notification.value.productId = newVal.productId;
            }
        });

        const handleImageUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    notification.value.imageBase64 = e.target.result.split(',')[1];
                };
                reader.readAsDataURL(file);
            }
        };

        const getAuthHeader = async () => {
            return {
                Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}`,
            };
        };
        
        const upsertNotification = async (notification) => {
            
            emit('upsertingNotification', true);
            
            try {
                const url = `${secretsConfig.UPSERT_NOTIFICATION_API_URL}`;
                //console.log("Upserting notification: " + JSON.stringify(notification));
                await axios.post(url, notification, { headers: await getAuthHeader() });
                emit('upsertingNotification', false);
                emit('close');
                //(response);
            } catch (error) {
                emit('upsertingNotification', false);
                //console.error(error);
            }


        };

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const monthName = monthNames[month];
            return `${day} ${monthName} ${year}`;
        };

        return { 
            notification, 
            handleSubmit, 
            handleConfirm, 
            handleCancel,
            handleDiscard,
            handleImageUpload,
            showConfirmDialog,
            formatDate 
        };
    }
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 25% 75%;

  img {
    position: relative;
    left: 15rem;
    top: 0.3rem;
    width: 50px;
    height: 50px;
  }
}

.notification-editor {
  position: fixed;
  margin-left: 4.6rem;
  top: 13rem;
  /* display: flex; */
  z-index: 4;
  justify-content: center;
  width: 70%;
  height: auto;
  background-color: #161617;
  border: 1px solid #7E7E7E;
  color: white;
  padding:25px;
  margin-top:20px;
  }

form {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 15%;
}

.inputArea {
    display: inline-block;
    margin-top: 30px;
}

textarea, input, select {
    width: 397px;
    border: 1px solid #7E7E7E;
    background: transparent;
    color: #E7E7E7;
    grid-column: 3;
}

label {
    grid-column: 2;
    margin-left: 50px;
}

.saveButtons {
    display: grid;
    grid-auto-flow: row;
    grid-column: 4;
    justify-items: end;
}

.save {
    background: linear-gradient(#4645F3 0%, #2E34E1 100%);
    border: 1px solid #5B5AF5;
    border-radius: 9px;
    color: #E7E7E7;
    width: 100px;
    height: 35px;
    grid-row: 1;
}

.discard {
    background: transparent;
    border: 1px solid #7E7E7E;
    border-radius: 9px;
    color: #9E9999;
    width: 100px;
    height: 35px;
    grid-row: 2;
}

hr {
    width: 90%;
    border: 1px solid #3D3D3D;
    margin-top: 20px;
}
.previewSection {
    margin: 30px 100px;

    h2 {
        color: #2F71F1;
        font-size: 20px;
    }

    .previewTile {
        display: grid;
        position: relative;
        grid-template-columns: 57px 253px 63px;
        width: 367px;
        height: 66px;
        border: none;
        border-radius: 8px;
        padding: 15px 20px;
        text-align: left;
        background-color: #202022;
        color: #FEFEFE;
    }

    .blueDot {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, #5354F6 0%, #3E40F5 100%);
    border-radius: 50%;
}

    img {
        grid-column: 1;
        width: 30px;
        height: 30px;
        justify-self: center;
        margin: 3px 20px auto 5px;
        }

    .elementText{
        grid-column: 2;
        width: 244px;

        h3 {
           width: 244px;
           font-size: 12px;
           line-height: 1.2;
           height: 32px;
           overflow: hidden;
           padding-bottom:0px;
           margin-bottom:2px;
        }

        p {
           width: 244px;
           height: 35px;
           font-size: 10px;
           overflow: hidden;
           color: #b0adad;
           margin-top: 0px;
           line-height: 1.2;
           margin-bottom: 0px;
        }
        
    }

    .contentRight {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        height: 100%;

        p{
            margin-top: 0;
        }
    }

    button {
        background: transparent;
        color: #979797;
        font-size: 12px;
        border: 1px solid #505050;
        border-radius: 7px;
        grid-column: 3;
        width: 65px;
        height: 25px;
    }

    h3 {
        padding-bottom: 10px;
        font-size: 17px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    a {
        grid-row: 2;
        grid-column: 2;
    }

    .date {
        padding-top: 0px;
        color: #979797;
        font-size:10px;
    }

    .lightgray {
        color: #9E9999;
        
    }
}

</style>
