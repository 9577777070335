<template>
    <div class="manager">
        <button class="plusButton" ref="plusButton" @click="toggleEditor"><span>+</span>Add Notification</button>
        <NotificationEditor ref="editor" v-show="showEditor" :initialNotification="selectedNotification" :products="products" @close="toggleEditor" @upsertingNotification="handleUpsertingNotification"/>
        <!-- <DataImage></DataImage> -->
        <hr>
        <div class="notificationList" v-for="(notification, index) in notifications" :key="index">
            <button class="notificationElement" @click.stop="() => handleNotificationClicked(notification)">
                <img v-if="notification.imageBase64" :src="'data:image/png;base64,' + notification.imageBase64" />
                <div class="elementText">
                    <h2>{{ notification.title }}</h2>
                    <p>{{ notification.body }}</p>
                    <a :href="notification.url" target="_blank">{{ notification.url }}</a>
                </div>
                <div class="contentRight">
                    <p class="lightgray">{{ convertTimestamp(notification.publicationTime) }}</p>
                    <button class="deleteButton" @click.stop="() => handleDeleteButtonClicked(notification)">
                        Delete
                    </button>
                </div>
            </button>
            <div class="productId lightgray">
                <p v-if="notification.productId">{{ notification.productId }}</p>
            </div>
        </div>
        <confirm-dialog 
            v-if="notificationToDelete && notificationToDelete.showConfirmDialog" 
            :show="notificationToDelete.showConfirmDialog"
            @confirm="handleConfirmDelete" 
            @cancel="handleCancelDelete">
        </confirm-dialog>
    </div>
</template>

<script>
//   import { ref, watch } from 'vue';
  import axios from 'axios';
  import secretsConfig from '../secretsConfig.js';
  import NotificationEditor from './NotificationEditor.vue';
  import { Amplify } from 'aws-amplify';
  import ConfirmDialog from './ConfirmDialog.vue';
    
  export default {
    name: 'HelloWorld',
    components: {
        NotificationEditor,
        ConfirmDialog,
    },
    methods: {
        handleUpsertingNotification (isUpserting) {
            this.$emit('upsertingNotification', isUpserting);
            
            if (!isUpserting)
                this.showEditor = false;
        },
        async getAuthHeader () {
            return {
                Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}`,
            };
        },
        convertTimestamp(timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleString(); // adjust this line according to your preferred date and time format
        },

        async fetchProducts () {
            try {
                const url = `${secretsConfig.LIST_PRODUCTS_API_URL}`;
                const response = await axios.get(url, { headers: await this.getAuthHeader() });
                let products = response.data;


                //filter all entries that have numberOfActivations = 0
                products = products.filter(product => product.numberOfActivations > 0);

                //filter productId "foodstamps-demo"
                products = products.filter(product => product.productId !== "foodstamps-demo");
                
                //sort alphabetically (by title)
                products.sort((a, b) => a.name.localeCompare(b.name));
                

                // Add the new 'Global' product at the beginning of the array
                products.unshift({
                    name: "Global",
                    productId: "global",
                    // Add any other necessary properties here
                });

                //Add a list separator after 1st entry
                products.splice(1, 0, {
                    name: "----------------",
                    productId: "separator",
                    // Add any other necessary properties here
                });

                return products;
            } catch (error) {
                //console.error(error);
            }
        },
        
        async fetchNotifications () {
            this.$emit('fetchingNotifications', true);

            const dayMultiplicator = 24 * 60 * 60 * 1000;
            const dateRange = {
                start: new Date(new Date().getTime () - 60 * dayMultiplicator),
                end: new Date(new Date().getTime () + 365 * dayMultiplicator) ,
            };

            try {
                const url = `${secretsConfig.LIST_NOTIFICATIONS_API_URL}?startTime=${dateRange.start.getTime ()}&endTime=${dateRange.end.getTime ()}`;
                const response = await axios.get(url);
                this.$emit('fetchingNotifications', false);
                return response.data.map(notification => ({ ...notification, showConfirmDialog: false }));
            } catch (error) {
                //console.error(error);
                this.$emit('fetchingNotifications', false);
            }
        },
        async deleteNotification (notification) {
            this.$emit('deletingNotification', true);
            try {
                //console.log ("Deleting notification: " + JSON.stringify(notification));
                const url = `${secretsConfig.DELETE_NOTIFICATION_API_URL}`;
                await axios.post(url, notification, { headers: await this.getAuthHeader() });
                //console.log(response);
            } catch (error) {
                //console.error(error);
            }

            this.$emit('deletingNotification', false);
        },

        toggleEditor() {
            this.selectedNotification = {...this.emptyNotification};
            this.showEditor = !this.showEditor;
        },
        clickOutside(event) {
            const editor = this.$refs.editor;
            const plusButton = this.$refs.plusButton;
            const deleteButton = this.$refs.deleteButton;
            const notificationElements = this.$refs.notificationElement;

            //console.log ("Refs: " + JSON.stringify(this.$refs));
            //console.log ("Event target: " + JSON.stringify(event.target));
            
            if (editor && !editor.$el.contains(event.target) 
                && event.target !== plusButton
                && event.target !== deleteButton
                && (!notificationElements || !notificationElements.some(el => el.contains(event.target)))) 
            {
                //console.log ("Click outside, closing editor");
                this.showEditor = false;
            }
        },
        handleNotificationClicked(notification) {
            //console.log("Notification clicked: " + JSON.stringify(notification));
            this.selectedNotification = notification;
            this.showEditor = true;
        },
        handleDeleteButtonClicked(notification) {
            this.notificationToDelete = notification;
            this.notificationToDelete.showConfirmDialog = true;
        },

        handleConfirmDelete() {
            if (this.notificationToDelete) {
                this.deleteNotification(this.notificationToDelete);
                this.notificationToDelete.showConfirmDialog = false;
                this.notificationToDelete = null;
            }
        },

        handleCancelDelete() {
            if (this.notificationToDelete) {
                this.notificationToDelete.showConfirmDialog = false;
                this.notificationToDelete = null;
            }
        },



    },
    mounted() {
        //console.log("Fetching notifications");
        this.$emit('settingUp', true);
        const fetchAndSetNotifications = async () => {
            this.notifications = await this.fetchNotifications();
            //console.log("Notifications: " + JSON.stringify(this.notifications));
        };
        fetchAndSetNotifications();

        //console.log("Fetching products");
        const fetchAndSetProducts = async () => {
            this.products = await this.fetchProducts();
            //console.log("Products: " + JSON.stringify(this.products));
        };
        fetchAndSetProducts();
        this.$emit('settingUp', false);
        document.addEventListener('click', this.clickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.clickOutside);
    },
    data () {                
        return {
            showEditor: false,
            notifications: [],
            emptyNotification: {
                id: null,
                publicationTime: new Date ().getTime (),
                productId: '',
                title: '',
                body: '',
                imageBase64: '',
                url: 'https://yum-audio.com',
                metaJson: null
            },
            selectedNotification: this.emptyNotification,
            products: [],
            showConfirmDeleteDialog: false,
            notificationToDelete: null,
        };
    },
  };
</script>


<style scoped>
.manager {
    background-color: #161617;
    color: #FEFEFE;
    min-width: 400px;
    min-height: 300px;
    margin-top: 20px;
}

button {
    cursor:pointer;
}
.plusButton {
    position: relative;
    top: 10px;
    left: 5rem;
    background: linear-gradient(#4645F3 0%, #2E34E1 100%);
    border: 1px solid #5B5AF5;
    border-radius: 9px;
    color: #FEFEFE;
    width: 204px;
    height: 35px;
}

span {
    border: 1px solid #FEFEFE;
    border-radius: 50%;
    padding: 2px 5px;
    margin-right: 10px;
}

hr {
    width: 90%;
    border: 1px solid #3D3D3D;
    margin-top: 40px;
}

.notificationList {
    display: grid;
    grid-template-columns: 75% 25%;
    margin-left: 5rem;
    margin-top: 2.5rem;
    align-items: center;
}
.notificationElement {
    display: grid;
    grid-template-columns: 10% auto 13%;
    width: 100%;
    border: none;
    border-radius: 15px;
    padding: 15px 20px;
    text-align: left;
    background-color: #202022;
    color: #FEFEFE;
}

.notificationElement:hover {
    border: 2px solid #366FF5;
}

.productId {
    padding-left: 20px;
    font-weight: none;
}

img {
    grid-column: 1;
    width: 70px;
    justify-self: center;
    margin: 15px 25px auto 40px;
    }

.elementText{
    grid-column: 2;
    width: 80%;
    padding-left: 20px;
    padding-bottom: 15px;
}

.contentRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    height: 100%;
}

.deleteButton {
    background: transparent;
    color: #F94144;
    border: 2px solid #F94144;
    border-radius: 9px;
    grid-column: 3;
    width: 90px;
    height: 40px;
}

h2 {
    padding-bottom: 10px;
    font-size: 18px;
}

a {
    grid-row: 2;
    grid-column: 2;
}

p {
    margin-top: 0px;
}

.lightgray {
    color: #9E9999;
}
</style>