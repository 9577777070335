import {Amplify} from 'aws-amplify';
import axios from 'axios';
import { SECRETS_API_URL } from '../secretsConfig.js';
import { load } from '../util/auth.js';

async function getSecrets() {
  try {
    const response = await axios.get(SECRETS_API_URL, { withCredentials: true });
    if (response && response.data) {
      return response.data;
    } else {
    //   console.error('SecretConfig response invalid', response);
    }
  } catch (error) {
    // console.error('Failed to get secret config: ', error);
  }

  return null;
}

export default async () => {
  try {
    const authorizedHeader = async () => {
      return {
        Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}`,
      };
    };
    //console.log ("Trying to get secrets...");
    const secrets = await getSecrets();

    if (secrets && secrets.IdentityPoolId && secrets.UserPoolId && secrets.ClientId && secrets.Region) {
      Amplify.configure({
        Auth: {
          identityPoolId: secrets.IdentityPoolId,
          userPoolId: secrets.UserPoolId,
          userPoolWebClientId: secrets.ClientId,
          region: secrets.Region,
          authenticationFlowType: 'USER_PASSWORD_AUTH',
          mandatorySignIn: false,
        },
        API: {
          endpoints: [
            {
              name: 'origin',
              endpoint: 'localhost:8080',
              custom_header: authorizedHeader,
            },
          ],
        },
      });

      await load();
    } else {
    //   console.error('Secrets missing required properties', secrets);
    }
  } catch (err) {
    // console.error('Error in default export function', err);
  }
};
